import { createConsumer } from '@rails/actioncable'

export cable = createConsumer()

export channels = {
  users_online: cable.subscriptions.create 'UsersOnlineChannel',
    connected: -> console.log "Connected to UsersOnlineChannel"
    subscribe: -> @perform 'subscribe'
    received: (data) ->
      if data['rebuild']
        @perform 'rebuild'
      # else if data['user_id'] is $.y.chat.currentUser.id and !data['online']
      #   @perform 'still_there'
      else
        $("span[data-user-state='#{data.user_id}']").toggleClass 'online', data.online
        $(document).trigger 'online:changed', data

  messages: cable.subscriptions.create 'MessagesChannel',
    connected: ->
      console.log "Connected to MessagesChannel"
    subscribe: ->
      @perform 'subscribe'
    chat: (contactId, message) ->
      @perform 'chat', { contact_id: contactId, message: message }
    composing: (contactId) ->
      @perform 'composing', { contact_id: contactId }
    paused: (contactId) ->
      @perform 'paused', { contact_id: contactId }
    read: (contactId) ->
      @perform 'read', { contact_id: contactId }
    webrtcRequest: (contactId, kind) ->
      @perform 'webrtc_request', { contact_id: contactId, kind: kind }
    webrtcAccept: (contactId) ->
      @perform 'webrtc_accept', { contact_id: contactId }
    webrtcStop: (contactId) ->
      @perform 'webrtc_stop', { contact_id: contactId }
    webrtcCancel: (contactId) ->
      @perform 'webrtc_cancel', { contact_id: contactId }
    webrtcReject: (contactId) ->
      @perform 'webrtc_reject', { contact_id: contactId }
    webrtcDescription: (contactId, description) ->
      @perform 'webrtc_description', { contact_id: contactId, description: description }
    webrtcIceCandidate: (contactId, iceCandidate) ->
      @perform 'webrtc_ice_candidate', { contact_id: contactId, ice_candidate: iceCandidate }
    received: (data)->
      if data['logout']
        location.href = '/logout'
      else if data['type'] == 'match'
        $('.match-animation').addClass('play')
        setTimeout ->
          $('.match-animation').removeClass('play');
        , 1800
      else
        $(document).trigger 'messages:received', data

  counters: cable.subscriptions.create 'CountersChannel',
    connected: -> console.log "Connected to CountersChannel"
    subscribe: -> @perform 'subscribe'
    received: (data)->
      dataField = @_eventTypes[data.type]
      return unless dataField
      
      dataAction = data.action || 'increment'
      store.dispatch 'counters/' + dataAction, dataField
    _eventTypes: { chat: 'chatMessages', form_view: 'formViews', notification: 'notifications' }
}
