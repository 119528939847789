#
# Chat²
#

import { channels } from './cable'
import { flashMessage, emoji, subscription, jsonUtils } from './main'
import Modal from './modal'

emoji.init()

import Vue from 'vue/dist/vue.esm'
import Vuex from 'vuex/dist/vuex.esm'
Vue.use Vuex

export default chat = {
  version: '1.0'

  vue: null

  start: ->
    @vue ||= new Vue {
      name: 'chat',
      el: '#chat',
      store,
      data: {
        isConnected: false,
        isOpened: false,
        conversations: [],
        activeConversation: null,
        selectMode: false,
        showUnread: false,
        textInput: '',
        searchInput: '',
        composingTimer: null,
        convLock: false,
        msgLock: false,
        webRTC: {
          active: false,
          enabled: false,
          kind: 'video',
          audioEnabled: true,
          videoEnabled: true,
          message: null,
          cancellingTimer: null
        },
        isMobile: false,
        isMobileConvPanel: false
      },
      computed: $.extend(
        Vuex.mapState('config', ['currentUser', 'device'])
        , {
          sortedConversations: -> (if @showUnread then @unreadConversations else @conversations).sort((c1, c2) => c2.updated_at - c1.updated_at)
          selectedConversations: -> @conversations.filter((c) => c.selected)
          unreadConversations: -> @conversations.filter((c) => c.incoming and c.unread)
          unreadCount: -> @unreadConversations.length
        }),
      watch:
        isOpened: (n, o) ->
          if n && @activeConversation
            if @activeConversation.unread
              channels.messages.read @activeConversation.user.id
              @activeConversation.unread = false

            @scrollChat()

        activeConversation: (n, o) ->
          return unless n

          if n.unread && @isOpened
            channels.messages.read n.user.id
            n.unread = false

          @scrollChat()
          @isMobileConvPanel = true

          # Init infinite messages list
          unless o
            @$nextTick =>
              $(@$refs.messages).scroll (e) =>
                return if @msgLock
                sb = $(e.target)
                @loadMessages() if sb.children().first().height() - sb.height() > 0 and sb.scrollTop() is 0

        currentUser: (n, o) ->
          return if o
          @loadConversations()

        selectedConversations: (n, o) ->
          @selectMode = n.length > 0

        unreadCount: (n, o) ->
          store.dispatch 'counters/set', { field: 'chatMessages', value: n }

        'webRTC.enabled': (n, o) ->
          console.log (if n then @webRTC.kind else '')
          document.querySelector('[data-webrtc-telltale]').dataset.value = if n then @webRTC.kind else ''

      methods: {
        show: ->
          @$nextTick => requestAnimationFrame =>
            @isOpened = true
            if @$refs.textInput
              @$nextTick => @$refs.textInput.focus()

        hide: -> @isOpened = false

        toggle: -> if @isOpened then @hide() else @show()

        open: (userId) ->
          return if userId is @currentUser.id
          @loadMessages(userId)

        conversation: (userId) -> @conversations.find((c) -> c.user.id is userId)

        conversationIndex: (userId) -> @conversations.findIndex((c) -> c.user.id is userId)

        loadConversations: ->
          # Params { offset: 0, unread: false, search: '' }
          @convLock = true
          url = new URL('/messages.json', document.location)
          url.search = new URLSearchParams({ offset: @conversations.length, search: @searchInput })
          fetch(url, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
            .then((response) => response.text())
            .then((jsonText) => JSON.parse(jsonText, jsonUtils.reviver))
            .then((data) =>
              @conversations = @conversations.concat(data)
              @activeConversation = null
              @convLock = false unless data.length < 20
            )

        setActiveConversation: (conversation, e) ->
          if e.target.type is 'checkbox' or e.target.previousElementSibling?.type is 'checkbox'
            e.stopPropagation()
            return

          if conversation is @activeConversation
            @isMobileConvPanel = true if @isMobile
          else
            @loadMessages(conversation.user.id)

        loadMessages: (userId) ->
          @msgLock = true
          userId ||= @activeConversation.user.id
          url = new URL("/messages/#{userId}.json", document.location)
          url.search = new URLSearchParams({ offset: @conversation(userId)?.messages?.filter((c) => c.type is 'chat')?.length || 0 })
          fetch(url, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
            .then((response) => response.text())
            .then((jsonText) => JSON.parse(jsonText, jsonUtils.reviver))
            .then((data) =>
              c = @conversation(data.user.id)
              if c
                c.user = data.user
                c.messages = data.messages.concat(c.messages)
              else
                m = data.messages.last
                c = {
                  user: data.user,
                  incoming: m?.incoming or false,
                  description: m?.description or '',
                  unread: false,
                  updated_at: m?.updated_at or new Date(),
                  formatted_updated_at: m?.formatted_updated_at or '',
                  messages: data.messages,
                  selected: false
                }
                @conversations.push c
                @$nextTick => @$refs.conversations.scrollTop = 0
              @activeConversation = c
              @show()
              @msgLock = false unless data.messages.length < 50
            )

        cancelSelection: ->
          conversation.selected = false for conversation in @selectedConversations
          @$nextTick => @$refs.conversations.dispatchEvent(new Event('y:domChanged', bubbles: true))

        deleteSelection: ->
          Modal.confirm I18n.t('common.delete_confirm_title'), I18n.t('js.chat.delete'), =>
            $.ajax { method: 'POST', url: '/messages/delete', global: true, data: {'_method': 'delete', 'user_ids[]': @selectedConversations.map((c) -> c.user.id)}, success: (data, _s, xhr) =>
              for userId in data.user_ids
                ci = @conversationIndex(parseInt(userId))
                @conversations.splice ci, 1
              document.dispatchEvent(new CustomEvent('ajax:success', detail: [null, null, xhr]))
            }

        messageReceived: (data) ->
          switch data['type']
            when 'chat', 'webrtc_request' then @addMessage(data)
            when 'composing'              then @conversation(data.contact_id)?.is_composing = true
            when 'paused'                 then @conversation(data.contact_id)?.is_composing = false
            when 'read'                   then @conversation(data.contact_id)?.unread = false
            when 'webrtc_accept'          then chat.webrtc.start()
            when 'webrtc_stop'            then chat.webrtc.stop()
            when 'webrtc_cancel'          then chat.webrtc.cancel()
            when 'webrtc_reject'          then chat.webrtc.reject()
            when 'webrtc_description'     then chat.webrtc.setDescription(data)
            when 'webrtc_ice_candidate'   then chat.webrtc.addIceCandidate(data)
            when 'ban'                    then flashMessage.create(I18n.t('common.alert.banned'), 'alert')
            when 'restrict'               then subscription.alert((if @currentUser.kind is 'female' then 'chat_female_html' else 'chat_male'), callback: => @hide())

        addMessage: (message) ->
          c = @conversation(message.contact_id)
          if c
            c.incoming = message.incoming
            c.description = message.description
            c.updated_at = @date(message.created_at)
            c.formatted_updated_at = message.formatted_created_at

            if message.type is 'webrtc_request' && message.status is 1
              @webRTC.kind    = message.kind
              @webRTC.message = message

            if message.incoming && c is @activeConversation && @isOpened
              channels.messages.read c.user.id
              c.unread = false
            else
              c.unread = true

            c.messages.push message

            @scrollChat() if c is @activeConversation
          else
            url = new URL('/messages.json', document.location)
            url.search = new URLSearchParams({ contact_id: message.contact_id })
            fetch(url, { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
              .then((response) => response.text())
              .then((jsonText) => JSON.parse(jsonText, jsonUtils.reviver))
              .then((data) => @conversations.push(data[0]))

          # Play sound
          @$refs.newMessage.play().catch(->) if message.incoming and (!@activeConversation or (!message.contact_id isnt @activeConversation.user.id or !@isOpened or !document.hasFocus()))

        sendMessage: ->
          return if @textInput.trim() is ''
          channels.messages.chat @activeConversation.user.id, @textInput
          @textInput = ''
          @stopComposing()

        composing: ->
          if @composingTimer
            clearTimeout @composingTimer
          else
            channels.messages.composing @activeConversation.user.id
          @composingTimer = setTimeout =>
            @stopComposing()
          , 3000

        stopComposing: ->
          channels.messages.paused @activeConversation.user.id
          clearTimeout @composingTimer
          @composingTimer = null

        webrtcRequest: (kind = 'video') ->
          if @webRTC.message
            flashMessage.create 'Appel en cours', 'alert'
            return
          @webRTC.active = true
          @webRTC.cancellingTimer = setTimeout =>
            @webrtcCancel()
          , 60000
          channels.messages.webrtcRequest @activeConversation.user.id, kind

        webrtcAccept: ->
          @webRTC.active = true
          channels.messages.webrtcAccept @activeConversation.user.id

        webrtcCancel: -> channels.messages.webrtcCancel @activeConversation.user.id

        webrtcReject: -> channels.messages.webrtcReject @activeConversation.user.id

        webrtcStop: -> channels.messages.webrtcStop @activeConversation.user.id

        toggleAudio: -> chat.webrtc.setAudioEnabled(@webRTC.audioEnabled = !@webRTC.audioEnabled)

        toggleVideo: -> chat.webrtc.setVideoEnabled(@webRTC.videoEnabled = !@webRTC.videoEnabled)

        emoticonizeInput: ->
          curPos = @$refs.textInput.selectionStart
          curLength = @textInput.length
          @textInput = emoji.emoticonize(@textInput)
          newPos = curPos + @textInput.length - curLength
          @$nextTick => @$refs.textInput.setSelectionRange(newPos, newPos)

        insertEmoticon: (emoticon) ->
          curPos = @$refs.textInput.selectionStart
          @textInput = @textInput.substring(0, curPos) + emoticon + @textInput.substring(curPos)
          @$nextTick =>
            @$refs.textInput.setSelectionRange curPos + emoticon.length, curPos + emoticon.length
            @$refs.textInput.focus()

        report: ->
          fetch("/messages/#{@activeConversation.user.id}/report", { headers: { 'X-Requested-With': 'XMLHttpRequest' } })
            .then((response) => response.text())
            .then((data) => (new Modal 'content', {content: data}).show())

        scrollChat: (smooth=false) ->
          @$nextTick => # Wait for the next DOM update to scroll
            # el = @$refs.messagesList.lastChild
            # el.scrollIntoView({ block: 'end', behavior: if smooth then 'smooth' else 'instant' }) if el? and el.nodeType is Node.ELEMENT_NODE
            @$refs.messages.scrollTop = @$refs.messages.scrollHeight

        format: (str) ->
          #console.log "format : #{str}" # Check the WTF :'(
          @nl2br(@linkify(str))
        nl2br: (str) -> str.replace /(\r\n|\n\r|\r|\n)/g, '<br/>'
        linkify: (str) ->
          str.replace new RegExp("(#{document.location.origin}\/[-a-zA-Z0-9@:%_\+.~#?&\/=]*)"), '<a href="$1" data-chat-close>$1</a>'
        t: (scope, options) -> I18n.t(scope, options)
        l: (scope, value, options) -> I18n.l(scope, value, options)
        date: (date) -> if date then new Date(date) else new Date()
      },
      mounted: ->
        @isMobile = true if $('body').hasClass('mobile')

        $(document).on 'click', '*[data-chat-trigger]', (e) =>
          e.preventDefault()
          uid = $(e.currentTarget).data('chat-trigger')
          if uid
            @open uid
          else
            @toggle()

        $(document).on 'click', 'a[data-chat-close]', (e) =>
          e.preventDefault()
          @hide()
          Turbolinks.visit(e.currentTarget.href) unless e.currentTarget.dataset.chatClose is 'prevent-default'


        $(document).on 'messages:received', (e, data) => @messageReceived(data)

        # Init infinite conversations list
        $(@$refs.conversations).scroll (e) =>
          return if @convLock
          sb = $(e.target)
          @loadConversations() if sb.children().first().height() - sb.height() - sb.scrollTop() < 300

        @loadConversations() if @currentUser

    }
    @webrtc.init @vue

  isConnected: -> @vue.isConnected

  show: -> @vue.show()

  hide: -> @vue.hide()

  toggle: -> @vue.toggle()

  webrtc: {
    _vue: null
    _user_id: null
    _peerConnection: null
    _localStream: null
    _remoteStream: null

    init: (vue) ->
      @_vue = vue

    init_connection: ->
      @_peerConnection = new RTCPeerConnection({ iceServers: [{
                                                   urls: "turns:turn1.nouslibertins.com:3478",
                                                   username: "nlcommon",
                                                   credential: "GWLmqYJUQki7"
                                                  },
                                                  { urls: 'stun:stun.l.google.com:19302' }
                                                ]})
      @_peerConnection.addEventListener 'icecandidate', (e) => @_onIceCandidate(e)
      @_peerConnection.addEventListener 'iceconnectionstatechange', (e) => @_onConnectionStateChange(e)
      @_peerConnection.addEventListener 'track', (e) => @_onTrack(e)

    start: ->
      @_changeMsgStatus 2
      return unless @_vue.webRTC.active
      @init_connection()
      @_user_id = @_vue.activeConversation.user.id
      navigator.mediaDevices.getUserMedia({ video: @_vue.webRTC.kind == 'video', audio: { echoCancellation: true, noiseSuppression: true } }).then (stream) =>
        @_localStream = stream
      .catch (e) =>
        console.log 'WebRTC::getUserMedia::Error'
        console.log e
        @_localStream = @void.videoStream()
      .finally =>
        unless @_vue.webRTC.active
          @reset()
          return
        @_vue.$refs.localVideo.srcObject = @_localStream
        @_localStream.getTracks().forEach (track) => @_peerConnection.addTrack(track,  @_localStream)
        @_peerConnection.createOffer().then (offer) =>
          @_peerConnection.setLocalDescription offer
          channels.messages.webrtcDescription @_vue.activeConversation.user.id, offer

    cancel: ->
      @_changeMsgStatus 4
      @reset()

    reject: ->
      @_changeMsgStatus 5
      @reset()

    stop: ->
      @_changeMsgStatus 0
      @reset()

    setAudioEnabled: (enabled) ->
      return unless @_localStream
      @_localStream.getAudioTracks().forEach (track) => track.enabled = enabled
      return

    setVideoEnabled: (enabled) ->
      return unless @_localStream
      @_localStream.getVideoTracks().forEach (track) => track.enabled = enabled
      return

    setDescription: (data) ->
      if @_peerConnection?
        @_peerConnection.setRemoteDescription data['description']
      else if @_vue.webRTC.active
        if @_vue.webRTC.cancellingTimer
          clearTimeout @_vue.webRTC.cancellingTimer
          @_vue.cancellingTimer = null
        @init_connection()
        @_user_id = data['contact_id']
        @_peerConnection.setRemoteDescription(data['description']).then =>
          navigator.mediaDevices.getUserMedia({ video: @_vue.webRTC.kind == 'video', audio: { echoCancellation: true, noiseSuppression: true } }).then (stream) =>
            @_localStream = stream
          .catch (e) =>
            console.log 'WebRTC::getUserMedia::Error'
            console.log e
            @_localStream = if @_vue.webRTC.kind == 'video' then @void.videoStream() else @void.audioStream()
          .finally =>
            if @_vue.webRTC.kind == 'video'
              @_vue.$refs.localVideo.srcObject = @_localStream
            else
              @_vue.$refs.localAudio.srcObject = @_localStream
            @_localStream.getTracks().forEach (track) => @_peerConnection.addTrack(track, @_localStream)
            @_peerConnection.createAnswer().then (answer) =>
              @_peerConnection.setLocalDescription answer
              channels.messages.webrtcDescription data['contact_id'], answer
      else
        @_changeMsgStatus 3

    addIceCandidate: (data) ->
      console.log  'WebRTC::Add_ice_candidate'
      @_peerConnection.addIceCandidate data['ice_candidate'] if @_peerConnection?

    void:
      blackVideo: (width = 640, height = 480) ->
        canvas = Object.assign(document.createElement('canvas'), { width: 640, height: 480 })
        canvas.getContext('2d').fillRect 0, 0, canvas.width, canvas.height
        # Green screen for testing purpose
        # ctx = canvas.getContext('2d')
        # ctx.fillStyle = 'green'
        # ctx.fillRect 0, 0, canvas.width, canvas.height
        stream = canvas.captureStream()
        Object.assign stream.getVideoTracks()[0], { enabled: false }

      silentAudio: ->
        ctx = new AudioContext()
        oscillator = ctx.createOscillator()
        dst = oscillator.connect(ctx.createMediaStreamDestination())
        oscillator.start();
        Object.assign dst.stream.getAudioTracks()[0], { enabled: false }

      videoStream: -> new MediaStream([@blackVideo(), @silentAudio()])

      audioStream: -> new MediaStream(@silentAudio())

    reset: ->
      console.log 'WebRTC::reset'
      @_vue.webRTC.active = false
      @_vue.webRTC.enabled = false
      @_vue.webRTC.message = null
      clearTimeout(@_vue.webRTC.cancellingTimer) if @_vue.webRTC.cancellingTimer
      @_vue.webRTC.cancellingTimer = null
      if @_user_id
        @_vue.conversation(@_user_id).ongoingCall = false
        @_user_id = null
      @_peerConnection?.close()
      @_peerConnection = null
      @_localStream?.getTracks().forEach (track) -> track.stop()
      @_localStream = null
      @_remoteStream = null
      @_vue.$refs.localVideo.srcObject = null
      @_vue.$refs.remoteVideo.srcObject = null
      @_vue.$refs.localAudio.srcObject = null
      @_vue.$refs.remoteAudio.srcObject = null

    _onIceCandidate: (e) ->
      console.log 'WebRTC::on_ice_candidate'
      return unless e.candidate?
      channels.messages.webrtcIceCandidate @_vue.activeConversation.user.id, e.candidate

    _onConnectionStateChange: (e) ->
      console.log "WebRTC::ice_connection_state_change : #{@_peerConnection.iceConnectionState}"
      switch @_peerConnection.iceConnectionState
        when 'failed', 'disconnected'
          @stop()
        when 'connected'
          @_vue.webRTC.enabled = true
          @_vue.conversation(@_user_id).ongoingCall = true
          @_changeMsgStatus 3

    _onTrack: (e) ->
      console.log 'WebRTC::on_track'
      @_vue.webRTC._remoteStream = e.streams[0]
      if @_vue.webRTC.kind == 'video'
        @_vue.$refs.remoteVideo.srcObject = @_vue.webRTC._remoteStream
      else
        @_vue.$refs.remoteAudio.srcObject = @_vue.webRTC._remoteStream
        @_vue.$refs.remoteUserAvatarUrl.src = @_vue.conversation(@_user_id).user.avatar_url

    # Status 0: terminated / 1: request / 2: init / 3: ongoing / 4: cancelled / 5: rejected
    _changeMsgStatus: (status) ->
      return unless @_vue.webRTC.message?
      @_vue.webRTC.message.status = status
      @_vue.webRTC.message = null if status is 0
  }
}
